

// GRADIENT MIXIN
@mixin gradientRedDarkRed {
  background: linear-gradient(to right, rgba($primary, 1), rgba($secondary, 1));
}

@mixin gradientDarkRedBlack {
  background: linear-gradient(to right, rgba($secondary, 1), rgba($app-black, 1));
}

@mixin gradientPinkRed {
  background: linear-gradient(to bottom, rgba($tertiary, 1), rgba($primary, 1));
}

@mixin gradientPinkDarkRed {
  background: linear-gradient(to top, rgba($tertiary, 1), rgba($secondary, 1));
}
  
  // ABSOLUTE CENTER
  @mixin absoluteCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  // TRANSITION PROPERTY
  @mixin transitionProperty {
    transition-duration: 1000ms;
    transition-property: all;
  }
  